<section class="loginPage">
    <div class="container">
        <div class="card">
            <div class="card-body">
                <div class="circle"></div>
                <header class="myHed text-center">
                    <img src="../../../assets/images/logo.png" width="60%"></header>
                <form class="main-form text-center" #data="ngForm" (ngSubmit)='submitLogin(data.value)'>
                        <div class="form-group my-0">
                                <label class="my-0">
                                    <i class="fa fa-user"></i>
                                    <input type="text" class="myInput" placeholder="Email" name="email" ngModel>
                                </label>
                            </div>
                        
                        <div class="form-group my-0">
                            <label>
                                <i class="fa fa-lock"></i>
                                <input type="password" class="myInput" placeholder="Password" name="password" ngModel>
                            </label>
                        </div>
                        <div class="alert" role="alert">
                            {{errorMessage}}
                         </div>
                        <div class="form-group">
                            <label style="width: 90%;">
                                <button class="button-animate button buttonClickLoading"  >
                                    <span class="dispText "  >LOGIN</span>
                                  </button>
                            </label>
                        </div>
                </form>
            </div>
        </div>
    </div>
</section>
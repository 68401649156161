import { Component, OnInit } from '@angular/core';
import { ApisService } from 'src/app/services/apis.service';
import { User } from 'src/app/models/users';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']

})
export class UsersComponent implements OnInit {
  users : User[]
  ids : number[] =[]; 
  
  constructor(private http :ApisService ,private router: Router) {
    let token = localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login']);
    }
   }
clickSweet(id : number){
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this imaginary file!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.http.deleteUser(id).subscribe(response =>{
        if(response.status == 1){
          this.http.getUsers().subscribe(response =>{
            this.users = response.data;
          })
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        }
      })
      
    // For more information about handling dismissals please visit
    // https://sweetalert2.github.io/#handling-dismissals
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      )
    }
  })
}
  
  ngOnInit(): void {
    this.http.getUsers().subscribe(response =>{
      this.users = response.data
    })
  
  }

  onChildAdded(e){
    Swal.fire(
      'Success',
      e,
      'success'
    )
    this.http.getUsers().subscribe(response =>{
      this.users = response.data;
    })
  }

  onNotificationSend(e){
    Swal.fire(
      'Sent',
      e,
      'success'
    )

  }
  selectItem(e, item){
    if(e)
    this.ids.push(item.id);
    else{
      let index = this.ids.indexOf(item.id)
      this.ids.splice(index , 1);
    }
  }
}
